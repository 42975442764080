export const environment = {
    production: false,
    debugMode: false,
    api: 'https://smartresidentback.creativitywebm.fr/api/',
    wss: 'https://smartresidentback.creativitywebm.fr',
    domainName: 'smartresidentback.creativitywebm.fr',

    // api: 'http://sapiai.ddns.net:27101/api/',
    // wss: 'sapiai.ddns.net:27101',
    // domainName: 'sapiai.ddns.net:27101'
};
