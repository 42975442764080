<div class="banner-element">
    <div class="banner-element_text">
        <h3 class="title">
         <strong>{{title.type}} {{formatDate(date)}}</strong>
        </h3>
        <p class="description">
            {{'BANNER.tenant' | translate}}

            <span class="highlight">{{tenant}}</span>
            <span *ngIf="action.includes('#addr#')">{{action.split('#addr#')[0]}}</span>
            <span *ngIf="action.includes('#addr#')" class="highlight">{{action.split('#addr#')[1]}}</span>
            {{actionType}}
   <!--         <span class="highlight">{{action}}</span>
            {{'BANNER.inApps' | translate}}-->
            <span class="highlight">{{address}}</span>
<!--            {{'BANNER.on' | translate}}
            <span class="highlight">{{formatDate(date)}}</span>-->
        </p>
    </div>
    <div class="btn-container" >
        <button class="sr-kit_button" data-color="primary" *ngIf="buttonText" (click)="buttonClick.emit()" [disabled]="disabledButton">
            {{buttonText}}
        </button>
    </div>
</div>
