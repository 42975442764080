<div class="right-panel"
     [class.opened]="dashboardService.rightPanelOpened"
     [class.profile-only]="profileOnly"
>
<!--    ParisNow: {{getParisNow()}}-->

    <div class="user-profile" [matMenuTriggerFor]="profileMenu">
        <div class="text-wrapper">
            <p class="welcome-message">{{'PROFILE_MENU.greetings.' + calcWelcomeTime() | translate}}</p>
            <p class="username">{{userService.user?.firstName}} {{userService.user?.lastName}}</p>
        </div>
        <div class="avatar">
            <span *ngIf="!userService.user?.image">
                {{userService.user?.firstName | firstLetter}}{{userService.user?.lastName | firstLetter}}
            </span>
            <img *ngIf="userService.user?.image" [src]="getAvatar()">
        </div>
    </div>
    <mat-menu #profileMenu="matMenu" class="profile-menu">
        <button mat-menu-item (click)="redirectToProfile()">
            {{'HEADER_MENU.profile' | translate}}
        </button>
        <button mat-menu-item [matMenuTriggerFor]="languagesMenu">
            {{'HEADER_MENU.language' | translate}}
        </button>
        <button mat-menu-item (click)="userService.logout()">
            {{'HEADER_MENU.logout' | translate}}
        </button>
    </mat-menu>

    <mat-menu #languagesMenu="matMenu" class="profile-menu">
        <button mat-menu-item (click)="translate('en')">
            {{'SETTINGS.BO-English_Language' | translate}}
        </button>
        <button mat-menu-item (click)="translate('fr')">
            {{'SETTINGS.BO-French_Language' | translate}}
        </button>
        <button mat-menu-item (click)="translate('de')">
            {{'SETTINGS.BO-German_Language' | translate}}
        </button>
    </mat-menu>

    <ng-content></ng-content>
</div>

<div class="right-panel_overlay"
     (click)="dashboardService.toggleRightPanel()"
     [class.opened]="dashboardService.rightPanelOpened"
     [class.disabled]="profileOnly"
></div>
