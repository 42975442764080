import {Injectable} from '@angular/core';
import {Subject, tap} from 'rxjs';
import {authorizationTables, SrRoles} from "../../common/sr-roles";
import {ApiService} from "./api.service";
import {environment} from "../../environments/environment";
import {UserService} from "./user.service";

interface DashboardServiceItem {
    key: string,
    icon: string,
    badge?: boolean,
    active?: boolean,
    access?: string[]
    submenu?: {
        key: string,
        access?: string[]
    }[]
}

@Injectable()
export class DashboardService {

    constructor(
        private api: ApiService,
        private userService: UserService,
    ) {
    }

    private _navigationOpened = false;
    get navigationOpened(): boolean {
        return this._navigationOpened;
    }

    set navigationOpened(value: boolean) {
        this._navigationOpened = value;
        this.changesTrigger.next(true);
    }

    private _rightPanelOpened = false;
    get rightPanelOpened(): boolean {
        return this._rightPanelOpened;
    }

    set rightPanelOpened(value: boolean) {
        this._rightPanelOpened = value;
        this.changesTrigger.next(value);
        this.rightPanelObserver.next(value);
    }

    public rightPanelObserver = new Subject<any>();
    public changesTrigger = new Subject<any>();

    public services: DashboardServiceItem[] = [
        {
            key: 'interventions',
            icon: 'service_interventions',
            badge: false,
            access: authorizationTables.dashboardInterventionAccess,
        },
        {
            key: 'polls',
            icon: 'service_polls',
            access: authorizationTables.dashboardPollsAccess,
        },
        {
            key: 'tenants',
            icon: 'service_tenants',
            access: authorizationTables.dashboardTenantsAccess,
        },
        // {
        //     key: 'points',
        //     icon: 'service_points',
        //     access: authorizationTables.dashboardPointsAccess,
        // },
        {
            key: 'management',
            icon: 'service_accounts',
            submenu: [
                {key: 'accounts', access: authorizationTables.accountsMngt},
                {key: 'config'},
                {key: 'settings', access: authorizationTables.settingParamMngt},
                {key: 'bots', access: authorizationTables.botsMngt},
            ]
        },
        {
            key: 'collaboration',
            icon: 'service_collaborative',
            access: authorizationTables.dashboardCollaborationAccess,
        },
        {
            key: 'providers',
            icon: 'service_phone_book',
            badge: false,
            access: authorizationTables.dashboardProviderAccess,
        },
        {
            key: 'advertisements',
            icon: 'service_advertisement',
            access: authorizationTables.dashboardAdsAccess,
        },
        {
            key: 'bails',
            icon: 'service_bails',
            access: authorizationTables.dashboardLeaseAccess,
        },
        {
            key: 'operations',
            icon: 'service_operations',
            access: authorizationTables.dashboardOperationAccess,
        },

        {
            key: 'lottery',
            icon: 'service_lottery',
            access: authorizationTables.dashboardPointsAccess,
        },

        {
            key: 'catalog',
            icon: 'service_points',
            access: authorizationTables.dashboardPointsAccess,
        },

        {
            key: 'stat',
            icon: 'service_stats',
            access: authorizationTables.dashboardPointsAccess,
        },
    ];

    public toggleNavigation() {
        this.navigationOpened = !this.navigationOpened;
    }

    public toggleRightPanel() {
        this.rightPanelOpened = !this.rightPanelOpened;
    }

    public countEnterprisePendingProviders() {

        if (![SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA].includes(this.userService.user?.role || '')) {
            return;
        }
        // call api countEnterprisePendingProviders
        return this.api.get(`${environment.api}others/countEnterprisePendingProviders`)
            .subscribe({
                next: (res: any) => {
                    console.log('count_EnterprisePendingProviders', res);
                    const service = this.services.find(s => s.key === 'providers');

                    if (service) {
                        service.badge = res.model.count > 0;
                    }
                }
            })
    }

}
