<div class="see-application-dialog">
    <div class="btn-line">
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>

    <!--    <div style="height: 24px">

        </div>-->
    <h3 class="heading">
        {{ 'Tenants-sponsor-T-title' | translate }}
    </h3>
    <div class="table-container gray-theme">
        <div class="table-wrapper">
            <table class="table-wrapper">
                <tr>
                    <th>{{ 'BO-Name-text' | translate }}</th>
                    <th>{{ 'BO-FirstName-text' | translate }}</th>
                    <th>{{ 'BO-ApartID-text' | translate }}</th>
                    <th>{{ 'BO-Email-text' | translate }}</th>
                    <th>{{ 'Config-Tenants-LeaseStart' | translate }}</th>
                    <th>{{ 'Config-Tenants-LeaseEnd' | translate }}</th>
                    <th>{{ 'BO-NbPoints' | translate }}</th>
                </tr>
                <!--                COMMENT: why put empty fields ??-->
                <tr>
                    <td>{{ data?.requesterUser?.lastName }}</td>
                    <td>{{ data?.requesterUser?.firstName }}</td>
                    <!--                    <td>-</td>-->
                    <td>{{ data?.residenceDisplayId }}<br>{{ data.apartmentDisplayId }}</td>
                    <td>{{ data?.requesterUser?.email }}</td>
                    <td>{{ formatDate(data?.dateStart) }}</td>
                    <td>{{ formatDate(data?.dateLastPayment) }}</td>
                    <td>{{ data?.requesterUser?.pts?.pts }}</td>
                </tr>
            </table>
        </div>
    </div>

    <h3 class="heading">
        {{ 'Tenants-sponsor-C-title' | translate }}
    </h3>

    <div class="table-container gray-theme">
        <div class="table-wrapper">
            <table class="table-wrapper">
                <tr>
                    <th>{{ 'BO-Name-text' | translate }}</th>
                    <th>{{ 'BO-FirstName-text' | translate }}</th>
                    <th>{{ 'BO-Email-text' | translate }}</th>

                </tr>
                <tr>
                    <td>{{ data?.requestedUser?.lastName }}</td>
                    <td>{{ data?.requestedUser?.firstName }}</td>
                    <td>{{ data?.requestedUser?.email }}</td>

                </tr>
            </table>
        </div>
    </div>

    <h3 class="heading">
        {{ 'Tenants-sponsor-A-title' | translate }}
    </h3>
    <p style="margin-top: 16px; margin-left: 16px">
        <td>{{ data?.requestedApartment?.residence?.displayID }} - {{ data?.requestedApartment?.displayID }}</td>

    </p>


    <h3 class="heading" style="margin-top: 32px">
        {{ 'BO-AsOfDate-text' | translate }}
        <span class="highlight-text">{{ formatDate(data?.createdAt) }}</span>
    </h3>

    <div class="dialog-buttons-container btn-container" style="margin-top: 32px; margin-bottom: 32px"
         *ngIf="showActions">
        <button class="sr-kit_button" data-color="primary" data-size="s" (click)="contact(data)">
            {{ 'BO-Contact-Button' | translate }}
        </button>

        <button class="sr-kit_button" data-color="primary" data-size="s" (click)="approve()"
                [disabled]="!data.hasContacted">
            {{ 'BO-Accept-Button' | translate }}
        </button>

        <button class="sr-kit_button" data-color="primary" data-size="s" (click)="refuse()">
            {{ 'BO-Refuse-Button' | translate }}
        </button>
    </div>


</div>

