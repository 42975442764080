<div class="see-application-dialog">
    <div class="btn-line">
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>

    <div class="main-wrapper">
        <div class="content">
<!--            <mat-form-field appearance="outline">-->
<!--                <mat-label>{{ 'Email subject and content required params' | translate }}</mat-label>-->
<!--                <input type="text" matInput [(ngModel)]="requiredParams" [disabled]="true">-->
<!--            </mat-form-field>-->
            <form [formGroup]="form" class="form-grid" autocomplete="off">
                <mat-form-field appearance="outline">
                    <mat-label>{{ "BO-Settings-Email_subject-text" | translate }}</mat-label>
                    <input type="text" matInput formControlName="subject" required>
<!--                    <mat-error *ngIf="form.get('subject').hasError('required')">
                        {{ 'This field is required' | translate }}
                    </mat-error>-->
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ "BO-Settings-Email_content-text" | translate }}</mat-label>
                    <textarea matInput formControlName="body" required style="height: 300px"></textarea>
            <!--        <mat-error *ngIf="form.get('content').hasError('required')">
                        {{ 'This field is required' | translate }}
                    </mat-error>-->
                </mat-form-field>
            </form>

            <div class="btn-container" style="display: flex; justify-content: center">

                <button *ngIf="true"

                        class="sr-kit_button save-btn"
                        data-color="primary"
                        data-size="l"
                        (click)="sendEmail()"
                        [disabled]="form.invalid"
                >
                    {{ "BO-Send-Button" | translate }}
                </button>


            </div>
        </div>
    </div>


</div>

