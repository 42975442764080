import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, EMPTY, Subject, tap, throwError} from 'rxjs';
import {parseJwt} from '../utils';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {LoaderService} from './loader.service';
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {appDebugLog} from "../new_utils";
import {SrRoles} from "../../common/sr-roles";
import {ApiService} from "./api.service";
import {MessageTypes} from "../app-consts";
// import {MatSnackBar} from "@angular/material/snack-bar";

type TokenStatus = 'ready' | 'pending' | 'empty';
type AuthForm = { email: string, password: string };

export interface UserModel {
    _id: string,
    email: string,
    firstName: string,
    lastName: string,
    image?: string,
    role: string,
    owner_id?: string,
    agency_id?: string
    entity: string,
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private readonly tokenRefreshInterval = 1500;
    private isLoginByToken: boolean = false;
    public tokenUpdated: Subject<any> = new Subject<any>();
    public tokenStatusUpdated: Subject<any> = new Subject<any>();
    private _token: string | undefined;
    private _tokenStatus: TokenStatus = 'empty';
    private tokenUpdateTimestamp: number | undefined;

    private _user: UserModel | undefined;
    public userUpdated: Subject<any> = new Subject<any>();
    public userPending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    get user(): UserModel | undefined {
        return this._user;
    }

    set user(value: UserModel | undefined) {
        this._user = value;
        this.userUpdated.next(value);
        console.log('USER:', value)
    }

    get token(): string | undefined {
        return this._token;
    }

    set token(value: string | undefined) {
        this._token = value;
        this.tokenStatus = value ? 'ready' : 'empty';
        this.tokenUpdateTimestamp = value ? Date.now() : undefined;
        this.tokenUpdated.next(value);
    }

    get tokenStatus(): TokenStatus {
        return this._tokenStatus;
    }

    set tokenStatus(value: TokenStatus) {
        this._tokenStatus = value;
        this.tokenStatusUpdated.next(value);
    }

    constructor(
        private http: HttpClient,
        private router: Router,
        private loadingService: LoaderService,
        private snack: MatSnackBar,
        private translateService: TranslateService,
        // private api: ApiService
    ) {
        let storedData = localStorage.getItem('userInfo');
        if (storedData) {
            const parsed = JSON.parse(storedData);
            this.user = parsed.user;
            this.token = parsed.token;
        }
        // @ts-ignore
        window['userId'] = this.user?._id;
    }

    public refreshToken() {
        if (this.isLoginByToken) return;
        this.tokenStatus = 'pending';
        this.http.post(`${environment.api}auth/refresh`, {}, {withCredentials: true})
            .subscribe({
                next: (response: any) => {
                    if (response && response.model && response.model.accessToken) {
                        const {_id, firstName, lastName, role, email, entity} = response.model;
                        this.user = {_id, firstName, lastName, role, email, entity};
                        this.token = response.model.accessToken;
                        localStorage.setItem('userInfo', JSON.stringify({
                            user: this.user,
                            token: this.token
                        }))
                    } else {
                        this.logout();
                    }
                },
                error: (err) => {
                    console.error(err);
                    this.logout();
                }
            })
    }


    public login(form: AuthForm) {
        if (!form.email || !form.password) throw Error('Invalid auth credentials');
        this.userPending.next(true);
        this.http.post(`${environment.api}auth/authorization/admin`, form).subscribe({
            next: (response: any) => {
                if (response && response.credentials && response.tokens) {
                    console.log('Auth response:', response,)
                    this.snack.dismiss();
                    this.user = response.credentials;
                    this.token = response.tokens.accessToken;
                    if (SrRoles.isTechnicalProvider(this.user)) {
                        this.router.navigate(['/dashboard']).then();
                        // this.router.navigate(['/provider']).then();
                    } else {
                        this.router.navigate(['/dashboard']).then();
                    }
                    localStorage.setItem('userInfo', JSON.stringify({
                        user: this.user,
                        token: this.token
                    }))
                    this.loadingService.clear();
                } else {
                    throw Error('Auth response invalid');
                }
                this.userPending.next(false);
            },
            error: (error: any) => {
                console.error(error?.error);
                this.userPending.next(false);
                this.snack.open(this.translateService.instant('BO-Login-MatchError-msg'), '', {
                    duration: 2000,
                });
            }
        })
    }

    public loginByToken(token: string) {
        // this.logout();
        // decode base64 token
        this.loadingService.add();
        const form = JSON.parse(atob(token));
        console.log('token_auth loginByToken: ', form);
        if (!form.email || !form.password) throw Error('Invalid auth credentials');
        form.isToken = true;
        this.isLoginByToken = true;
        const lang = form.lang || 'fr';
        this.userPending.next(true);
        this.http.post(`${environment.api}auth/authorization/admin`, form).subscribe({
            next: (response: any) => {
                if (response && response.credentials && response.tokens) {
                    console.log('token_auth Auth response:', response,)
                    this.snack.dismiss();
                    this.user = response.credentials;
                    this.token = response.tokens.accessToken;
                    if (SrRoles.isAmbassadorUser(this.user)) {
                        console.log('token_auth: navigate to tracking page');
                        this.router.navigate([`/dashboard/provider-interventions/tracking/${form.requestId}`],
                            {
                                queryParams: {
                                    lang
                                }
                            }
                        ).then();
                        // this.router.navigate(['/provider']).then();
                    } else {
                        // this.logoutToErrorPage('0');
                        this.logout();
                    }
                    localStorage.setItem('userInfo', JSON.stringify({
                        user: this.user,
                        token: this.token
                    }))
                    // this.loadingService.clear();
                } else {
                    this.logoutToErrorPage('1');
                    // throw Error('Auth response invalid');
                }
                this.userPending.next(false);
                this.loadingService.remove();
            },
            error: (error: any) => {
                console.error('login by token error', error?.error);
                this.userPending.next(false);
                this.logoutToErrorPage('2');
                this.loadingService.remove();

            }
        })
    }

    public logout() {
        this.user = undefined;
        this.token = undefined;
        this.tokenStatus = 'empty';
        localStorage.removeItem('userInfo');
        this.router.navigate(['/auth']).then();
        this.loadingService.clear();
    }

    public logoutToErrorPage(error: string = '0') {
        this.user = undefined;
        this.token = undefined;
        this.tokenStatus = 'empty';
        localStorage.removeItem('userInfo');
        this.router.navigate([`/sr-error-page`], {
            queryParams: {
                error: error
            }
        }).then();
        this.snack.open(this.translateService.instant('Login fail, error code: ' + error), '', {
            duration: 2000,
        });
        this.loadingService.clear();
    }

    public isTokenRefreshAvailable(): boolean {
        if (!this.tokenUpdateTimestamp || this.tokenStatus !== 'pending') return true;
        return Date.now() - this.tokenUpdateTimestamp > this.tokenRefreshInterval;
    }

    public isTokenAlive(): boolean {
        if (!this.token) return false;
        try {
            const parsedToken = parseJwt(this.token);
            if (!parsedToken || !parsedToken.exp) return false;
            return parsedToken.exp * 1000 - new Date().getTime() >= 30000;
        } catch (e) {
            console.error('INNER', e);
            return false;
        }
    }

    getUserName(user: any) {
        if (!user?.firstName || !user?.lastName) return 'INVALID USER';
        return `${user.firstName.trim()} ${user.lastName.trim()}`
    }

    getFlat(data: any) {
        // appDebugLog('getFlat: ', data);
        const residence = data?.residenceDisplayId;
        const apartment = data?.apartmentDisplayId;
        return `${residence} - ${apartment}`
        /*        if (!data?.street || !data?.flat) return 'INVALID ADDRESS';
                return `${data.street.trim()} ${data.flat.trim()}`*/
    }

    getApartmentAddressDisplay(object: {
        residenceDisplayId: string,
        apartmentDisplayId: string
    }) {
        return `${object.residenceDisplayId} ${object.apartmentDisplayId}`;

    }

    updateUserInfo(user: UserModel) {
        this.user = user;
        localStorage.setItem('userInfo', JSON.stringify({
            user: this.user,
            token: this.token
        }))

    }


    getTextFromMultiLang(jsonStringify: string) {
        if (jsonStringify.toString().startsWith('{') && jsonStringify.toString().endsWith('}')) {
            return JSON.parse(jsonStringify)[this.translateService.currentLang];
        } else {
            return jsonStringify;
        }
    }


    showMessage(message: any,) {

        if (message.messageType === MessageTypes.textMultiLang) {
            return MessageTypes.getMessageOfMultiLangText(message.message, this.translateService.currentLang);
        } else if (message.message.startsWith('{"key":')) {
            try {
                const json = JSON.parse(message.message);
                return this.translateService.instant("CHAT." + json.key, json.args);
            } catch (e) {
                console.error('Error while parsing message', message, e);
            }
        } else if (message.message.toString().startsWith('{') && message.message.toString().endsWith('}')) {
            try {
                const json = JSON.parse(message.message);
                return json[this.translateService.currentLang];
            } catch (e) {
                console.error('Error while parsing message', message, e);
            }
        }
        return message.message;
    }


}
