<div class="dialog-wrapper" style="margin: 24px;">
    <h1 class="title">{{'FILTERS.' + data.title}}</h1>
    <p class="content">
        <ng-container *ngIf="data.type === 'date'">
            <mat-calendar [(selected)]="data.value"></mat-calendar>
        </ng-container>
        <ng-container *ngIf="data.type === 'dateRange'">
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Start date" [(ngModel)]="data.value[0]">
                    <input matEndDate placeholder="End date" [(ngModel)]="data.value[1]">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="data.type === 'text'">
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Filter text</mat-label>
                <input type="text" matInput [(ngModel)]="data.value">
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="data.type === 'select'">
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Select a values</mat-label>
                <mat-select [(ngModel)]="data.value" [multiple]="data.multiple">
                    <mat-option *ngFor="let option of isAsyncList(data.items) ? (data.items | async) : data.items"
                                [value]="option[data.itemValue]"
                    >
                        <ng-container *ngIf="data.categoryLocalization; else defaultView">
                            {{getCategoryName(option)}}
                        </ng-container>
                        <ng-template #defaultView>
                            {{option[data.itemTitle]}}
                        </ng-template>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="data.type === 'rating'">
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Select a rating</mat-label>
                <mat-select [(ngModel)]="data.value">
                    <mat-option *ngFor="let option of data.items" [value]="option[data.itemValue]">
                        {{option[data.itemTitle]}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </p>
    <div class="btn-container">
        <button

            class="sr-kit_button view-more"
            data-color="primary"
            data-size="l"
            (click)="dialogRef.close(true)"
        >
            Confirm
        </button>
        <button
            class="sr-kit_button view-more"
            data-color="secondary"
            data-size="l"
            (click)="resetValue()"
        >
            Reset
        </button>
    </div>
</div>
