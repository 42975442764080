<mat-form-field  style="width: 100%;">
    <mat-label>{{label}}</mat-label>
    <input type="text" [formControl]="search" matInput [matAutocomplete]="auto" (focusin)="focusIn($event)" (focusout)="focusOut($event)" [disabled]="disabled">
    <mat-icon class="clear-ico" (click)="clearClick($event)" matSuffix style="cursor: pointer;" *ngIf="clearable">clear</mat-icon>

    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectOption($event)">
        <mat-option *ngFor="let option of data" [value]="getValueByPath(option)">
            <span class="raw-title" *ngIf="rawTitle">
                ({{getValueByPath(option)}})
            </span>
            {{getTitleByPath(option)}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
