import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ProviderService} from "../../../../shared/services/provider.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FilesService} from "../../../../shared/services/files.service";
import {ConfigService} from "../../../../shared/services/config.service";
import {SponsorService} from "../../../../shared/services/sponsor.service";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../../../shared/services/user.service";
import {LoaderService} from "../../../../shared/services/loader.service";

@Component({
    selector: 'app-email-editor-dialog',
    templateUrl: './email-editor-dialog.component.html',
    styleUrls: ['./email-editor-dialog.component.scss']
})
export class EmailEditorDialogComponent implements OnInit {

    form = new UntypedFormGroup(
        {
            subject: new UntypedFormControl('', Validators.required),
            body: new UntypedFormControl('', Validators.required),
        }
    )
    requiredParams: string = '';

    constructor(
        public dialogRef: MatDialogRef<EmailEditorDialogComponent>,
        private configService: ConfigService,
        private sponsorService: SponsorService,
        private translateService: TranslateService,
        private userService: UserService,
        private loader: LoaderService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        console.log('__trace _EmailEditorDialogComponent', data)

    }

    ngOnInit() {
        this.configService.getMailTemplateByTemplateId(this.data.templateId, this.data.agency).subscribe((res: any) => {
            console.log('res of get by template id', res)
            // this.form.patchValue(res.model)

            const newModel = {
                subject: res.model.subject[this.translateService.currentLang],
                body: res.model.content[this.translateService.currentLang]
            }

            this.replaceParams(newModel, res.model.boData);
            this.form.patchValue({
                subject: newModel.subject,
                body: newModel.body
            });

            this.requiredParams = res.model.params.join(', ');
        })
    }


    replaceParams(model: any, boData: any) {
        // Hello {{sponsored_name}}
        //
        // {{sponsor_first_name}} {{sponsor_last_name}} recommended you for an apartment in {{residence}}
        // We would love to meet you and offer to make an appointment with you to schedule a visit.
        //
        //     See you soon
        // {{agency_sign}}
        // {{agency_name}}
        // {{agency_email}}

        //
        // _id: string,
        //     email: string,
        //     firstName: string,
        //     lastName: string,
        //     image?: string,
        //     role: string,
        //     owner_id?: string,
        //     agency_id?: string
        // entity: string,
        const data = this.data;
        const body: any = {
            residence: data.requestedApartment.residence.displayID,
            sponsored_name: data.requestedUser.firstName,
            // LNSponsored: data.requestedUser.lastName,
            sponsor_first_name: data.requesterUser.firstName,
            sponsor_last_name: data.requesterUser.lastName,
            agency_sign: boData.signature || 'agency_sign',
            agency_name: boData.name || 'agency_name',
            agency_email: boData.email || 'agency_email'
        }

        if (model.subject) {
            for (let key in body) {
                model.subject = model.subject.replace(`{{${key}}}`, body[key].toString());
            }
        }

        if (model.body) {
            for (let key in body) {
                model.body = model.body.replace(`{{${key}}}`, body[key].toString());
            }
        }
    }

    close() {
        this.dialogRef.close();
    }

    sendEmail() {
        if (this.form.invalid) return;
        const data = this.data;
        const body = {
            to: data.requestedUser.email,
            residence: data.requestedApartment.residence.displayID,
            FNSponsored: data.requestedUser.firstName,
            LNSponsored: data.requestedUser.lastName,
            FNSponsor: data.requesterUser.firstName,
            LNSponsor: data.requesterUser.lastName,
            sponsorId: data._id,
            userEmail: {
                subject: this.form.value.subject,
                body: this.form.value.body.replace(/\n/g, '<br>')
            }
        }
        this.loader.add();
        this.sponsorService.sponsorContactRequestedUser(body).subscribe({
            next: () => {
                this.loader.remove();
                data.hasContacted = true;
                this.snackBar.open(this.translateService.instant('DIALOGS.sendEmailSuccess'),);
                this.dialogRef.close(true);
            }, error: () => {
                this.loader.remove();
                this.snackBar.open(this.translateService.instant('DIALOGS.sendEmailError'),);
                this.dialogRef.close();

            }
        });
    }
}
