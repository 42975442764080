import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AmbassadorService} from "../../services/ambassador.service";
import {TranslateService} from "@ngx-translate/core";
import {SponsorService} from "../../services/sponsor.service";
import {formatDate} from "../../utils";
import {UserService} from "../../services/user.service";
import {authorizationTables} from "../../../common/sr-roles";
import {LoaderService} from "../../services/loader.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
    EmailEditorDialogComponent
} from "../../../pages/dashboard/dialogs/email-editor-dialog/email-editor-dialog.component";

@Component({
    selector: 'app-sponsor-detail',
    templateUrl: './sponsor-detail.component.html',
    styleUrls: ['./sponsor-detail.component.scss']
})
export class SponsorDetailComponent {

    showActions = false;
    hasContacted = false;

    constructor(
        public dialogRef: MatDialogRef<SponsorDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sponsorService: SponsorService,
        private translateService: TranslateService,
        private userService: UserService,
        private loader: LoaderService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog

    ) {
        console.log('SponsorDetailComponent data: ', data);
        if (authorizationTables.sponsorModify.includes(this.userService.user!.role)) {
            this.showActions = true;
        } else {
            this.showActions = false;
        }


    }

    protected readonly formatDate = formatDate;

    approve() {
        this._activate(true);

    }

    refuse() {
        this._activate(false);

    }

    _activate(activated: boolean) {
/*        console.error('this.data', this.data)
        return;*/
        this.loader.add();
        this.sponsorService.manageCandidate(activated, this.data.requestedUser/*._id, this.data.requestedUser.firstName, this.data.requestedUser.lastName */).subscribe({
            next: () => {
                this.loader.remove();
                this.sponsorService.getLast();
                this.dialogRef.close(true);
                this.sponsorService.dataMayChange.next(true);
            }, error: () => {
                // this.snackBar.open(this.translateService.instant('DIALOGS.actionError'));
                this.loader.remove();
                // this.dialogRef.close(false);

            }
        });
    }

    close() {
        this.dialogRef.close(event);
    }

    contact(data: any) {
        this.dialog.open(EmailEditorDialogComponent, {
            data: {
                templateId: 'Sponsored-candidate-Email',
                ...data
            },
            height: '800px',
            width: '1200px',
        }).afterClosed().subscribe((res: any) => {
            console.log('close dialog with resutl', res);
            if (res) {
                data.hasContacted = true;
            }
        });
        // const body = {
        //     to: data.requestedUser.email,
        //     residence: data.requestedApartment.residence.displayID,
        //     FNSponsored: data.requestedUser.firstName,
        //     LNSponsored: data.requestedUser.lastName,
        //     FNSponsor: data.requesterUser.firstName,
        //     LNSponsor: data.requesterUser.lastName,
        //     sponsorId: data._id,
        // }
        // this.loader.add();
        // this.sponsorService.sponsorContactRequestedUser(body).subscribe({
        //     next: () => {
        //         this.loader.remove();
        //         data.hasContacted = true;
        //         this.snackBar.open(this.translateService.instant('DIALOGS.sendEmailSuccess'),);
        //     }, error: () => {
        //         this.snackBar.open(this.translateService.instant('DIALOGS.sendEmailError'),);
        //         this.loader.remove();
        //     }
        // });

    }
}


// body.to,
//     body.residence,
//     body.FNSponsored,
//     body.LNSponsored,
//     body.FNSponsor,
