import {isDevMode} from "../pages/dashboard/shared/debug-info/debug-info.component";

export default class AppConsts {
    static readonly supportedImages = ".jpg,.jpeg,.png";

    static get longText() {
        if (isDevMode()) {
            return 'ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi. ' +
                'Proin porttitor, orci nec nonummy molestie, enim est eleifend mi, non fermentum diam nisl sit amet erat. Duis semper. Duis arcu massa, scelerisque vitae, consequat in, pretium a, enim. Pellentesque congue. ' +
                'Ut in risus volutpat libero pharetra tempor. Cras vestibulum bibendum augue. Praesent egestas leo in pede. Praesent blandit odio eu enim. Pellentesque sed dui ut augue blandit sodales. ';

        } else {
            return '';
        }
    }

    static supportedLanguages = ['en', 'fr', 'de']

}

export class MessageTypes {
    static readonly singleActionPrefix = "SingleActionBlockPrefix#";
    static readonly adApprovalRequest = "${singleActionPrefix}AdApprovalRequest";
    static readonly textMultiLang = "MultiLangFreeText";
    static readonly freeText = "FreeText";
    static readonly ratingResult = "RatingResult";

    static getMessageOfMultiLangText(jsonMsg: string, lang: string) {
        const msg = JSON.parse(jsonMsg);
        if (msg[lang]) {
            return msg[lang];
        } else {
            return msg;
        }
    }
}
