import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {LoaderService} from './loader.service';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {SrRoles} from "../../common/sr-roles";
import {SponsorDetailComponent} from "../components/sponsor-detail/sponsor-detail.component";
import {MatDialog} from "@angular/material/dialog";
import {
    EmailEditorDialogComponent
} from "../../pages/dashboard/dialogs/email-editor-dialog/email-editor-dialog.component";

@Injectable()
export class SponsorService {

    lastList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    allSponsors: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    dataMayChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private api: ApiService,
        private loader: LoaderService,
        private snackBar: MatSnackBar,
        private translateService: TranslateService,
        private dialog: MatDialog
    ) {
    }

    getLast() {
        this.loader.add();
        this.api.get(`${environment.api}data/sponsorship/last`).subscribe({
            next: (value: any) => {
                this.loader.remove();
                if (!value?.model) return;
                if (Array.isArray(value.model)) {
                    this.lastList.next(value.model);
                } else {
                    this.lastList.next([]);
                }
                if (!environment.production) {
                    console.log('LAST SPONSORSHIPS:', value.model)
                }
            }, error: () => this.loader.remove()
        })
    }

    getAll() {
        this.loader.add();
        this.api.get(`${environment.api}data/sponsorship/getAll`).subscribe({
            next: (value: any) => {
                this.loader.remove();
                if (!value?.model) return;
                if (Array.isArray(value.model)) {
                    this.allSponsors.next(value.model);
                } else {
                    this.allSponsors.next([]);
                }

            }, error: () => this.loader.remove()
        })
    }

    manageCandidate(activated: boolean, requestedUser: any) {
        delete requestedUser.activated;
        return this.api.put(`${environment.api}manage/candidate/account`, {...requestedUser, 'activate': activated,});
    }

    sponsorContactRequestedUser(body: any) {
        return this.api.post(`${environment.api}manage/sponsor/contactRequestedUser`, body);

    }

    isDetailSponsorDisabled(role: string) {
        return role === SrRoles.ROLE_OWNER;
    }

    openSponsorDialog(data: any, role: string) {
        if (this.isDetailSponsorDisabled(role)) return;


        this.dialog.open(SponsorDetailComponent, {
            data
        });
    }

}
