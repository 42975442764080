import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogoComponent} from './logo/logo.component';
import {SubHeaderComponent} from './sub-header/sub-header.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {BannerComponent} from './banner/banner.component';
import {RightPanelComponent} from './right-panel/right-panel.component';
import {RectangularComponent} from './rectangular/rectangular.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenuModule} from '@angular/material/menu';
import {RightPanelItemComponent} from './right-panel-item/right-panel-item.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {RectangularAddComponent} from './rectangular-add/rectangular-add.component';
import {FirstLetterPipe} from '../pipes/firstLetter.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BannerNewComponent } from './banner-new/banner-new.component';
import {PgSelectComponent} from './pg-select/pg-select.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { SponsorDetailComponent } from './sponsor-detail/sponsor-detail.component';


@NgModule({
    declarations: [
        LogoComponent,
        SubHeaderComponent,
        BannerComponent,
        RightPanelComponent,
        RectangularComponent,
        RightPanelItemComponent,
        RectangularAddComponent,
        FirstLetterPipe,
        BannerNewComponent,
        PgSelectComponent,
        SponsorDetailComponent,
    ],
    exports: [
        LogoComponent,
        SubHeaderComponent,
        BannerComponent,
        RightPanelComponent,
        RectangularComponent,
        RightPanelItemComponent,
        RectangularAddComponent,
        FirstLetterPipe,
        BannerNewComponent,
        PgSelectComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        RouterModule,
        TranslateModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule
    ]
})
export class ComponentsModule {
}
