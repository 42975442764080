<div class="text">
    <h3 class="title">
        <ng-content select="[bannerHeader]"></ng-content>
    </h3>
    <p class="description">
        <ng-content select="[bannerBody]"></ng-content>
    </p>
</div>
<div class="btn-container">
    <button class="sr-kit_button" data-color="primary" (click)="buttonClick.emit($event)">
        <ng-content select="[bannerButton]"></ng-content>
    </button>
</div>
