import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {formatDate} from '../../utils';

@Component({
    selector: 'rectangular-item',
    templateUrl: './rectangular.component.html',
    styleUrls: ['./rectangular.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RectangularComponent implements OnInit {

    @Input()
    title: string = '';
    @Input()
    date: string = '';
    @Input()
    description: string = '';

    @Input()
    subDescription: string = '';
    @Input()
    type: 'statistics' | 'action' | undefined;
    @Input()
    actionText: string = '';
    @Input()
    actionLink: string | undefined;

    @Input()
    actionDisabled: boolean = false;

    @Output()
    actionClick: EventEmitter<any> = new EventEmitter<any>();
    @Input()
    isActive: boolean = true;

    @Input()
    isActive2: boolean = false;

    @Input()
    isPurple: boolean = false;

    @Input()
    options: any[] | undefined;
    @Input()
    value: any;

    public formatDate = formatDate;

    constructor() {
    }

    ngOnInit(): void {
    }

    debug(data: any) {
        console.log(data)
    }

    isDisabled(option: any) {
        // option.title === 'terminate' && value.status !== 'IN_PROGRESS'

        if (option.isDisable) {
            return option.isDisable(this.value);
        }
        return false;
    }

    isHide(option: any) {
        if (option.isHide) {
            return option.isHide(this.value);
        }
        return false;
    }
}
