import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
    errorCode: any | '';
    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,

    ) {

        this.activeRoute.queryParams.subscribe({
            next: (queryParams: any) => {
                // console.log('paramsxxx: ', queryParams);
                this.errorCode = queryParams.error;
            }
        });
    }

}
