import {inject, Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';
import {SrRoles} from "../../common/sr-roles";

/*@Injectable()
export class LoggedGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        if (!this.userService.user) {
            this.router.navigate(['auth']).then();
            return false;
        }
        return true;
    }
}*/
export const loggedGuard: CanActivateFn = (route, state):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const router = inject(Router);
    const userService = inject(UserService);

    const url = state.url;
    console.log('SR_OPEN_URL:', url)
    // return false;

    if (url.startsWith('/tokenLogin')) {
        // get token from url params
        const token = route.params['token'];
        console.log('TOKEN:', token)
        // token is that last part of the url
        // const token = url.split('/').pop();
        // console.log('TOKEN:', token)
        // const email = 'huutuesn@gmail.com'
        // const password = '111111';
        // const requestId = '6671a1fcf480836ffbf30e87';
        // const form = {
        //     email,
        //     password,
        //     requestId,
        // }
        //
        // const base64 = btoa(JSON.stringify(form));
        // console.log('base64encode:', base64)
        userService.loginByToken(token)
        return true;
    } else {
        if (!userService.user) {
            router.navigate(['auth']).then();
            return false;
        }
        return true;
    }
    // return true;
}

/*
export const mytestGuard: CanActivateFn = (route, state):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree  => {
    return true;
};
*/
