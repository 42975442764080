import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DashboardService} from '../../services/dashboard.service';
import {Subscription} from 'rxjs';
import {UserService} from '../../services/user.service';
import {getRenderedImage} from '../../utils';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from "../../../environments/environment";
import {DateTime} from "ts-luxon";

@Component({
    selector: 'sr-right-panel',
    templateUrl: './right-panel.component.html',
    styleUrls: ['./right-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelComponent implements OnInit, OnDestroy {

    renderImage = getRenderedImage;

    @Input()
    background: boolean = true;
    @Input()
    profileOnly: boolean = false;

    private profileOnlySubscriber: Subscription | undefined;
    private visibilitySubscriber: Subscription | undefined;

    constructor(
        public dashboardService: DashboardService,
        private changes: ChangeDetectorRef,
        public userService: UserService,
        private router: Router,
        private translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.profileOnlySubscriber = this.dashboardService.rightPanelObserver
            .subscribe((value) => {
                if (this.profileOnly && value) {
                    this.dashboardService.rightPanelOpened = false;
                }
                this.changes.detectChanges();
            });
        this.visibilitySubscriber = this.dashboardService.changesTrigger
            .subscribe(() => this.changes.detectChanges());
    }

    ngOnDestroy() {
        this.profileOnlySubscriber?.unsubscribe();
        this.visibilitySubscriber?.unsubscribe();
    }

    calcWelcomeTime() {
        const currentHours = new Date().getHours();
        if (currentHours > 0 && currentHours <= 6) return 'goodNight';
        if (currentHours > 6 && currentHours <= 12) return 'goodMorning';
        if (currentHours > 12 && currentHours <= 18) return 'goodAfternoon';
        else return 'goodEvening';
    }

    getAvatar() {
        if (this.userService.user === undefined || this.userService.user.image === undefined) return;
        return this.renderImage(this.userService.user.image);
    }

    redirectToProfile() {
        this.router.navigate([`dashboard/management/myprofile/edit/${this.userService.user?._id}`]).then();
    }

    translate(lang: string) {
        this.translateService.use(lang);
        localStorage.setItem('lang', lang);
    }

    getParisNow() {
        if (environment.production) return '';
        if (!environment.debugMode) return '';
        return DateTime.now().setZone('Europe/Paris').toString()?.split('.')[0];
    }
}
