import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
// import {LoggedGuard} from '../shared/guards/logged.guard';
import {ProviderGuard} from '../shared/guards/provider.guard';
import {TechnicalGuard} from '../shared/guards/technical.guard';
import {loggedGuard} from "../shared/guards/logged.guard";
import {ErrorPageComponent} from "../pages/dashboard/error-page/error-page.component";

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    // anchorScrolling: 'enabled',
    // scrollOffset: [0, 64],
};

const routes: Routes = [
    {
        path: 'tokenLogin/:token',
        loadChildren: () => import('src/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [loggedGuard, TechnicalGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('src/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [loggedGuard, TechnicalGuard]
    },
    {
        path: 'auth',
        loadChildren: () => import('src/pages/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'provider',
        loadChildren: () => import('src/pages/provider-board/provider-board.module').then(m => m.ProviderBoardModule),
        canActivate: [ProviderGuard]
    },
    {
        path: 'sr-error-page',
        component: ErrorPageComponent,
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
    providers: [ ProviderGuard, TechnicalGuard]
})
export class AppRoutingModule {
}
