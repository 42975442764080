import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {LoaderService} from './loader.service';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Subject, tap} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ConfigService {

    public incidentConfigList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public mailTemplateList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public smsTemplateList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public msgTemplateList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(
        private api: ApiService,
        private loader: LoaderService,
        private snackBar: MatSnackBar,
        private translateService: TranslateService
    ) {
    }

    loadIncidentList() {
        this.loader.add();
        this.api.get(`${environment.api}data/incidentID/list`).subscribe({
            next: (value: any) => {
                this.incidentConfigList.next(value.model);
                this.loader.remove();
            }, error: () => this.loader.remove()
        })
    }

    createIncident(payload: any) {
        this.loader.add();
        const callBack = new Subject()
        this.api.post(`${environment.api}data/incidentID`, payload).subscribe({
            next: (value: any) => {
                console.log(value);
                this.loader.remove();
                callBack.next(true)
                callBack.complete()
                this.snackBar.open(
                    this.translateService.translations[this.translateService.currentLang]?.NOTIFICATIONS.saved,
                    '', {duration: 3000}
                )
            }, error: (err: any) => {
                this.loader.remove()
                callBack.error(err)
                this.snackBar.open(
                    err,
                    '', {duration: 3000}
                )
            }
        })
        return callBack
    }

    editIncident(payload: any) {
        this.loader.add();
        const callBack = new Subject()
        this.api.put(`${environment.api}data/incidentID/modify`, payload).subscribe({
            next: (value: any) => {
                console.log(value);
                this.loader.remove();
                callBack.next(true)
                callBack.complete()
                this.snackBar.open(
                    this.translateService.translations[this.translateService.currentLang]?.NOTIFICATIONS.saved,
                    '', {duration: 3000}
                )
            }, error: (err: any) => {
                this.loader.remove()
                callBack.error(err)
                this.snackBar.open(
                    err,
                    '', {duration: 3000}
                )
            }
        })
        return callBack
    }

    removeIncident(id: string) {
        this.loader.add();
        this.api.delete(`${environment.api}data/incidentID/${id}`).subscribe({
            next: () => {
                this.loader.remove();
                this.loadIncidentList();
            }, error: () => this.loader.remove()
        })
    }

    getSingleIncident(id: string) {
        this.loader.add();
        return this.api.get(`${environment.api}data/incidentID/${id}`).pipe(
            tap(() => this.loader.remove())
        )
    }

    getBotBail() {
        this.loader.add();
        const callBack = new Subject()
        this.api.get(`${environment.api}bail/bot/logic`).subscribe({
            next: (response: any) => {
                this.loader.remove();
                console.log('get bail bot logic', response)
                callBack.next(response)
                callBack.complete()
            }, error: (err: any) => {
                this.loader.remove()
                callBack.error(err)
                this.snackBar.open(
                    err,
                    '', {duration: 3000}
                )
            }
        })
        return callBack
    }

    getBotIntervention() {
        this.loader.add();
        const callBack = new Subject()
        this.api.get(`${environment.api}intervention/bot/logic`).subscribe({
            next: (response: any) => {
                this.loader.remove();
                console.log(response)
                callBack.next(response)
                callBack.complete()
            }, error: (err: any) => {
                this.loader.remove()
                callBack.error(err)
                this.snackBar.open(
                    err,
                    '', {duration: 3000}
                )
            }
        })
        return callBack
    }

    setBotBail(form: any) {
        this.loader.add();
        const callBack = new Subject()
        this.api.put(`${environment.api}bail/bot/logic`, form).subscribe({
            next: (response: any) => {
                this.loader.remove();
                console.log(response)
                callBack.next(true)
                callBack.complete()
                this.snackBar.open(
                    this.translateService.translations[this.translateService.currentLang]?.NOTIFICATIONS.saved,
                    '', {duration: 3000}
                )
            }, error: (err: any) => {
                this.loader.remove()
                callBack.error(err)
                this.snackBar.open(
                    err,
                    '', {duration: 3000}
                )
            }
        })
        return callBack
    }

    setBotIntervention(form: any) {
        this.loader.add();
        const callBack = new Subject()
        this.api.put(`${environment.api}intervention/bot/logic`, form).subscribe({
            next: (response: any) => {
                this.loader.remove();
                console.log(response)
                callBack.next(true)
                callBack.complete()
                this.snackBar.open(
                    this.translateService.translations[this.translateService.currentLang]?.NOTIFICATIONS.saved,
                    '', {duration: 3000}
                )
            }, error: (err: any) => {
                this.loader.remove()
                callBack.error(err)
                this.snackBar.open(
                    err,
                    '', {duration: 3000}
                )
            }
        })
        return callBack
    }

    // getInterventionSurveyBotLogic
    getInterventionSurveyBotLogic() {
        this.loader.add();
        const callBack = new Subject()
        this.api.get(`${environment.api}others/getInterventionSurveyBotLogic`).subscribe({
            next: (response: any) => {
                this.loader.remove();
                console.log(response)
                callBack.next(response)
                callBack.complete()
            }, error: (err: any) => {
                this.loader.remove()
                callBack.error(err)
                this.snackBar.open(
                    err,
                    '', {duration: 3000}
                )
            }
        })
        return callBack
    }
    // updateInterventionSurveyBotLogic
    updateInterventionSurveyBotLogic(form: any) {
        this.loader.add();
        const callBack = new Subject()
        this.api.post(`${environment.api}others/updateInterventionSurveyBotLogic`, form).subscribe({
            next: (response: any) => {
                this.loader.remove();
                console.log(response)
                callBack.next(true)
                callBack.complete()
                this.snackBar.open(
                    this.translateService.translations[this.translateService.currentLang]?.NOTIFICATIONS.saved,
                    '', {duration: 3000}
                )
            }, error: (err: any) => {
                this.loader.remove()
                callBack.error(err)
                this.snackBar.open(
                    err,
                    '', {duration: 3000}
                )
            }
        })
        return callBack
    }
    getMailTemplateList() {
        this.loader.add();
        this.api.get(`${environment.api}config/getAllMailTemplates`).subscribe({
            next: (value: any) => {
                console.log('mail template list', value.model);
                this.mailTemplateList.next(value.model);
                this.loader.remove();
            }, error: () => this.loader.remove()
        })
    }

    getMailTemplateById(id: string) {
        this.loader.add();
        return this.api.get(`${environment.api}config/getMailTemplateById/${id}`).pipe(
            tap(() => this.loader.remove())
        )
    }

    // getMailTemplateByTemplateId
    getMailTemplateByTemplateId(templateId: string, agencyId: string) {
        this.loader.add();
        return this.api.get(`${environment.api}config/getMailTemplateByTemplateId/${templateId}/${agencyId}`).pipe(
            tap(() => this.loader.remove())
        )
    }
    createMailTemplate(payload: any) {
        this.loader.add();
        const callBack = new Subject()
        this.api.post(`${environment.api}config/createMailTemplate`, payload).subscribe({
            next: (value: any) => {
                console.log(value);
                this.loader.remove();
                callBack.next(true)
                callBack.complete()
                this.snackBar.open(
                    this.translateService.translations[this.translateService.currentLang]?.NOTIFICATIONS.saved,
                    '', {duration: 3000}
                )
            }, error: (err: any) => {
                this.loader.remove()
                callBack.error(err)

            }
        })
        return callBack
    }

    getSmsTemplateList() {
        this.loader.add();
        this.api.get(`${environment.api}config/getAllSmsTemplates`).subscribe({
            next: (value: any) => {
                console.log('sms template list', value.model);
                this.smsTemplateList.next(value.model);
                this.loader.remove();
            }, error: () => this.loader.remove()
        })
    }


    // createSmsTemplate
    createSmsTemplate(payload: any) {
        this.loader.add();
        const callBack = new Subject()
        this.api.post(`${environment.api}config/createSmsTemplate`, payload).subscribe({
            next: (value: any) => {
                console.log(value);
                this.loader.remove();
                callBack.next(true)
                callBack.complete()
                this.snackBar.open(
                    this.translateService.translations[this.translateService.currentLang]?.NOTIFICATIONS.saved,
                    '', {duration: 3000}
                )
            }, error: (err: any) => {
                this.loader.remove()
                callBack.error(err)

            }
        })
        return callBack
    }

    // getSmsTemplateById
    getSmsTemplateById(id: string) {
        this.loader.add();
        return this.api.get(`${environment.api}config/getSmsTemplateById/${id}`).pipe(
            tap(() => this.loader.remove())
        )
    }



    getMsgTemplateList() {
        this.loader.add();
        this.api.get(`${environment.api}config/getAllMsgTemplates`).subscribe({
            next: (value: any) => {
                console.log('msg template list', value.model);
                this.msgTemplateList.next(value.model);
                this.loader.remove();
            }, error: () => this.loader.remove()
        })
    }


    // createSmsTemplate
    createMsgTemplate(payload: any) {
        this.loader.add();
        const callBack = new Subject()
        this.api.post(`${environment.api}config/updateMsgTemplate`, payload).subscribe({
            next: (value: any) => {
                console.log(value);
                this.loader.remove();
                callBack.next(true)
                callBack.complete()
                this.snackBar.open(
                    this.translateService.translations[this.translateService.currentLang]?.NOTIFICATIONS.saved,
                    '', {duration: 3000}
                )
            }, error: (err: any) => {
                this.loader.remove()
                callBack.error(err)

            }
        })
        return callBack
    }

    // getMsgTemplateById
    getMsgTemplateById(id: string) {
        this.loader.add();
        return this.api.get(`${environment.api}config/getMsgTemplateById/${id}`).pipe(
            tap(() => this.loader.remove())
        )
    }


    // call api: test/testSendMail
    testSendMail(body: any) {
        this.loader.add();
        const callBack = new Subject()
        this.api.post(`${environment.api}test/testSendMail`, body).subscribe({
            next: (value: any) => {
                console.log(value);
                this.loader.remove();
                callBack.next(true)
                callBack.complete()
                // this.snackBar.open(
                //     "Mail sent successfully. Please check your inbox.",
                //     '', {duration: 3000}
                // )
            }, error: (err: any) => {
                this.loader.remove()
                callBack.error(err)
                callBack.complete()

            }
        })
        return callBack
    }
}
