import {Component, Input} from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-debug-info',
  templateUrl: './debug-info.component.html',
  styleUrls: ['./debug-info.component.scss']
})
export class DebugInfoComponent {
    @Input() tag: string = '';
    isDevMode() {
        // return environment.debugMode;
        return isDevMode();
    }
}

export function isDevMode() {
    return environment.api.includes('192.168.1.201');
}
