import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'rectangular-add',
    templateUrl: './rectangular-add.component.html',
    styleUrls: ['./rectangular-add.component.scss']
})
export class RectangularAddComponent implements OnInit {

    @Input() buttonText: string = '';
    constructor() {
    }

    ngOnInit(): void {
        this.buttonText = this.buttonText || 'COMMON.addNewItem';
    }

}
